<template>
  <div>
    <div class="space-between align-center">
      <h3 class="color3">Change Password</h3>
    </div>
    <br />
    <b-alert
      :show="alert.dismissCountDown"
      dismissible
      :variant="alert.variant"
      @dismissed="alert.dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="mb-0">{{ alert.message }}</p>
    </b-alert>
    <br />
    <div class="form-container--small">
      <label>Password Lama</label><br />
      <b-form-input
        size="sm"
        placeholder="Password Lama"
        v-model="formData.old_password"
        type="password"
        :state="oldPasswordV"
      ></b-form-input>
      <b-form-invalid-feedback :state="oldPasswordV">
        Silahkan isi password lama.
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="oldPasswordV"> </b-form-valid-feedback>
      
      <label class="mt-4">Password Baru</label><br />
      <b-form-input
        placeholder="Password Baru"
        v-model="formData.password"
        type="password"
        :state="passwordV"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback :state="passwordV">
        Silahkan isi password baru.
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="passwordV"> </b-form-valid-feedback>
      
      <label class="mt-4">Konfirmasi Password Baru</label><br />
      <b-form-input
        placeholder="Konfirmasi Password Baru"
        v-model="formData.password_confirmed"
        type="password"
        :state="passwordConfirmedV"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback :state="passwordConfirmedV">
        Konfirmasi password tidak sama.
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="passwordConfirmedV">
      </b-form-valid-feedback>
      <br /><br />

      <my-button type="blue" rounded class="w-100" disabled v-if="isLoading">
        <b-spinner small></b-spinner>
        <span class="sr-only">Loading...</span>
      </my-button>
      <my-button type="blue" rounded class="w-100" v-else @click="submit">
        SIMPAN
      </my-button>
      <br /><br />
    </div>
    <br /><br /><br /><br /><br />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      alert: {
        variant: "success",
        message: "Success",
        response: "",
        dismissCountDown: 0
      },
      formData: {
        old_password: null,
        password: null,
        password_confirmed: null
      },
      validation: false,
      isLoading: false
    };
  },
  computed: {
    oldPasswordV: function() {
      if (this.validation) {
        return this.formData.old_password ? true : false;
      } else {
        return null;
      }
    },
    passwordV: function() {
      if (this.validation) {
        return this.formData.password ? true : false;
      } else {
        return null;
      }
    },
    passwordConfirmedV: function() {
      if (this.validation) {
        return this.formData.password_confirmed === this.formData.password
          ? true
          : false;
      } else {
        return null;
      }
    },
    ...mapGetters({
      isLoggedIn: "isLoggedIn"
    })
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.push("/login");
    }
  },
  methods: {
    ...mapActions({
      changePassword: types.CHANGE_PASSWORD
    }),
    countDownChanged(dismissCountDown) {
      this.alert.dismissCountDown = dismissCountDown;
    },
    showAlert(seconds) {
      this.alert.dismissCountDown = seconds;
    },
    submit() {
      this.validation = true;

      if (this.oldPasswordV && this.passwordV && this.passwordConfirmedV) {
        this.isLoading = true;
        this.changePassword(this.formData)
          .then(response => {
            this.isLoading = false;
            this.formData = {
              old_password: null,
              password: null,
              password_confirmed: null
            };
            this.alert.variant = "success";
            this.alert.message = "Password berhasil diubah";
            this.alert.response = response;
            this.showAlert(5);
          })
          .catch(error => {
            this.isLoading = false;
            this.alert.variant = "danger";
            this.alert.message = "Password gagal diubah";
            this.alert.response = error;
            this.showAlert(5);
          });
      }
    }
  }
};
</script>
